<template>
  <div :class="backgroundView">
    <br>
    <!-- <div :class="bannerView" >BANNER SEGURO OBLIGATORIO SOAP 2024</div> -->
    <div :class="bannerView"></div>
    <br><br>
    <br v-if="screen <= 800">
    <br v-if="screen <= 800">
    <div :class="cartView">
      <CarritoCompra :patentes="patentes" :e1="e1" @reload="reaload()"></CarritoCompra>
    </div>

    <div :class="topView">
      <v-form class="text-center" ref="form" v-model="valid">
        <div>
          <br>
          <div :class="titleBox">
            <b :class="title1View">Consulta tu</b>
            <b :class="title2View">SOAP</b>
          </div>

          <v-container :class="containerStyleView">
            <v-col :class="colView">
              <br v-if="screen <= 800">
              <div style="max-width: 500px; width: 100%;">
                <label :class="labelPatentView" class="labelPatent">Ingresa tu patente</label>
                <v-text-field :class="patentFieldView" v-model="patente" :rules="patenteRules" maxlength="6" minlength="5"
                  label="Ej: ABDC12" required solo @change="Mayus()"></v-text-field>
              </div>
              <br v-if="screen <= 800">
              <div style="max-width: 500px; width: 100%;">
                <label :class="labelMailView">Ingresa tu RUT</label>
                <v-text-field :class="mailFieldView" v-model="newRut" label="RUT" :rules="rutRules" required solo
                  @input="changeRut"></v-text-field>
              </div>
            </v-col>
          </v-container>

          <br>

          <div class="divCaptcha">
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
            <puntos />
          </div>

          <br><br>

          <v-btn :class="btnSearchView" @click="validate()">Buscar Patente</v-btn>

          <br>

        </div>

        <br><br>

      </v-form>

    </div>
    <div v-if="screen > 800" :class="tableConsulta">
      <template v-if="(patentesLoading == false)">
        <br v-if="screen > 800">
        <br v-if="screen > 800">
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="5" class="elevation-1" no-data-text="NO HAY REGISTROS ¡AÑADE TU SOAP!">
          <template v-slot:item.actions="{ item }">
            <v-btn class="btnDownload" @click="DescargarArchivo(item)">
              Descargar
            </v-btn>
          </template>
          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>

        </v-data-table>
      </template>
      <br><br>
    </div>
    <br>
    <!-- <v-col :class="btnBackBox" class="text-center">
      <v-btn :class="btnBackView" class="btnBack" @click="redirectClientIndex()">VOLVER AL INICIO</v-btn>
    </v-col> -->
    <br>
    <div v-if="screen <= 800" :class="tableConsulta">
      <template v-if="(patentesLoading == false)">
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="5" class="elevation-1" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">
          <template v-slot:item.actions="{ item }">
            <v-btn class="btnDownload" @click="DescargarArchivo(item)">
              Descargar
            </v-btn>
          </template>
          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>

        </v-data-table>
      </template>
      <br><br>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import ShieldService from "../services/shield.service";
import RutUtils from "../utils/rut.utils";
import swal from 'sweetalert';
import CarritoCompra from "@/components/Carrito.vue";
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "InitForm",
  components: {
    VueRecaptcha,
    CarritoCompra,
  },
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    bannerView: null,
    topView: null,
    backgroundView: null,
    cartView: null,
    title1View: null,
    title2View: null,
    labelPatentView: null,
    titleBox: null,
    patentFieldView: null,
    mailFieldView: null,
    colView: null,
    containerStyleView: null,
    labelMailView: null,
    tableConsulta: null,
    btnSearchView: null,
    btnBackView: null,
    btnBackBox: null,
    validRut: "",
    newRut: "",
    captcha: null,
    pathForChange: null,
    screen: window.screen.width,
    vistaLabelRut: null,
    vistaTituloRut: null,
    patente: null,
    rut: null,
    patentes: [],
    patentesLoading: true,
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => (v && v.length <= 6) || 'La patente debe tener máximo 6 caracteres',
      (v) => (v && v.length > 4) || 'La patente debe tener mínimo 5 caracteres',
      (v) => /^[^\s]+$/.test(v) || 'La patente no puede contener espacios',
      (v) => /^[A-Za-z0-9]+$/.test(v) || 'La patente solo puede contener letras y números',
    ],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', sortable: false, },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', sortable: false, },
      { text: 'Correo', value: 'personEmail', align: 'center', sortable: false, },
      { text: 'Valor SOAP', value: 'price', align: 'center', sortable: false, },
      { text: 'Descargar', value: 'actions', sortable: false, align: 'center', sortable: false, },
    ],
  }),
  methods: {
    async validate() {

      if (this.$refs.form.validate()) {
        this.patentes = []
        var carritos = await ShieldService.consultaCar(this.patente, this.rut)
        if (carritos.status == 'ok') {
          this.patentes = []
          this.patentes.push(carritos.data)
          this.patentesLoading = false

        } else if (carritos == 400) {
          swal({
            title: "¡Lo sentimos!",
            text: "La patente ingresada no coincide con el Rut",
            icon: "info",
            button: "Ok",
          });
          this.patentesLoading = true
        } else {
          swal({
            title: "¡Lo sentimos!",
            text: "No tenemos registros correspondientes a la patente ingresada.",
            icon: "info",
            button: "Ok",
          });
          this.patentesLoading = true
        }
      }
    },
    async reaload() {
      location.reload();
    },
    async redirectClientIndex() {
      // this.$router.push({ path: "/consulta" });
      window.location.href = process.env.VUE_APP_DOMINIO + this.pathForChange + '/inicio'
    },
    Mayus() {
      this.patente = this.patente.replace(/\s+/g, '');
      this.patente = this.patente.toUpperCase();
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async DescargarArchivo(item) {


      if (item.pdf) {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(item.pdf.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vehiculo-${item.patenteNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else {

        try {
          // reCaptcha v3 token
          await this.$recaptchaLoaded()
          const token = await this.$recaptcha('getAmazonia');

          var amazoniaRes = await ShieldService.getAmazonia(item.numeroPoliza, token);

          if (amazoniaRes.status == 'ok') {
            const byteCharacters = atob(amazoniaRes.data.data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${item.patenteNumber}-${item.personNames}.pdf`);
            document.body.appendChild(link);

            link.click();
          }

        } catch (error) {
          console.log(error)
        }

      }
    },
    changeRut(rut) {
      this.rut = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
  },
  async mounted() {
    this.pathForChange = localStorage.getItem('currentPath');
    if (this.screen > 800) {
      this.bannerView = 'bannerView'
      this.topView = 'topView'
      this.backgroundView = 'backgroundView'
      this.cartView = 'cartView'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.labelPatentView = 'labelPatentView'
      this.titleBox = 'titleBox'
      this.patentFieldView = 'patentFieldView'
      this.mailFieldView = 'mailFieldView'
      this.colView = 'colView'
      this.containerStyleView = 'containerStyleView'
      this.labelMailView = 'labelMailView'
      this.btnSearchView = 'btnSearchView'
      this.btnBackView = 'btnBackView'
      this.tableConsulta = 'tableConsultaDesk'
      this.btnBackBox = 'btnBackBox'

      this.vistaTituloRut = 'labelNameRut'
      this.vistaLabelRut = 'labelRut'
    } else if (this.screen <= 800) {
      this.tableConsulta = 'tableConsultaMov'
      this.bannerView = 'bannerView2'
      this.topView = 'topView2'
      this.backgroundView = 'backgroundView2'
      this.cartView = 'cartView2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.labelPatentView = 'labelPatentView2'
      this.titleBox = 'titleBox2'
      this.patentFieldView = 'patentFieldView2'
      this.mailFieldView = 'mailFieldView2'
      this.colView = 'colView2'
      this.containerStyleView = 'containerStyleView2'
      this.labelMailView = 'labelMailView2'
      this.btnSearchView = 'btnSearchView2'
      this.btnBackView = 'btnBackView2'
      this.btnBackBox = 'btnBackBox2'



      this.vistaTituloPatente = 'labelNamePatente2'
      this.vistaLabelPatente = 'labelPatente2'
      this.vistaTituloRut = 'labelNameRut2'
      this.vistaLabelRut = 'labelRut2'
    }
  },
};
</script>

<style lang="css" scoped>
.title1View {
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  font-weight: bold;
  font-size: 24px;
}

.title1View2 {
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  font-weight: bold;
  font-size: 20px;
  margin-left: -38%;
}

.title2View {
  margin-left: 1%;
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  font-weight: bold;
  font-size: 24px;
}

.title2View2 {
  margin-left: 2%;
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  font-weight: bold;
  font-size: 20px;
}

.tableConsultaDesk {
  max-width: 53%;
  margin: auto;
}

.tableConsultaMov {
  max-width: 75%;
  margin: auto;
}

.obligatorio {
  text-align: center;
  font: normal normal normal 30px/36px Arial;
  letter-spacing: 0px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
  font-family: 'Nunito Sans', sans-serif;
}

.vigencia {
  text-align: center;
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0px;
  color: #1269B0;
  opacity: 1;
  font-family: 'Nunito Sans', sans-serif;
}

.backgroundView {
  background-color: #F3F3F3;
}

.backgroundView2 {
  background-color: #FFFFFF;
}

.topView {
  background: #FFF 0% 0% no-repeat padding-box !important;
  box-shadow: 3px 3px 5px 0px #00000029;
  opacity: 1;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: auto;
  margin-top: -4%;
}

.btnDownload {
  background-color: #EF7C00 !important;
  color: #FFF;
  border-radius: 6px;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-transform: none;
  font-family: 'Nunito Sans', sans-serif;
}

.topView2 {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  /* box-shadow: 3px 3px 5px 0px #00000029; */
  opacity: 1;
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15.5%;
  margin-top: 1%;
}

.titulo {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #1269B0;
  opacity: 1;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 32px;
  letter-spacing: 0.96px;
  color: #1269B0;
  opacity: 1;
}

.labelNamePatente2 {
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  letter-spacing: 0px;
  color: #1269B0;
  opacity: 1;
  margin-left: -10%;

}

.labelPatente2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.labelNameRut {
  margin-left: 32%;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  opacity: 1;
}

.labelRut {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 54%;
  font-family: 'Nunito Sans', sans-serif;
}

.labelRut2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  font-family: 'Nunito Sans', sans-serif;
}

.labelNameRut2 {
  font-size: 13px;
  letter-spacing: 0px;
  color: #1269B0;
  opacity: 1;
  margin-left: -25%;
  font-family: 'Nunito Sans', sans-serif;
}

.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -17%;
  font-size: 46px;
  color: #1269B0;
  font-family: 'Nunito Sans', sans-serif;

}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -43%;
  font-size: 28px;
  color: #1269B0;
  margin-bottom: -15%;
  width: 80%;
  margin-left: 10%;
  font-family: 'Nunito Sans', sans-serif;

}

.containerStyle {
  width: 70% !;
  margin-left: auto;
  margin-right: auto;
  /* background-color: yellowgreen; */
  border-radius: 16px;
}

.btnSearch {
  width: 400px;
  height: 60px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

#app>div>main>div>div>div.topView>form {
  width: 100%;
}

.divCaptcha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -2%;
  margin-bottom: -2%;
}

.containerStyle {
  justify-content: center;
  display: flex;
  margin-left: -17%;
}

.labelPatentView {
  margin-right: 62%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1269B0;
  opacity: 1;
}

.labelPatentView2 {
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  margin-left: 30%;
  font-size: 100%;
}

.campoPatente {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 40%;
  text-transform: uppercase;
}

.cartView {
  margin-top: 11%;
  margin-left: -7%;
}

.cartView2 {
  margin-left: 45%;
  margin-top: 16%;
  /* margin-bottom: -23%; */
}

.titleBox {
  margin-top: -0.8%;

}

.titleBox2 {
  margin-top: -14%;
}

.patentFieldView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;
}

.patentFieldView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 43%;
  text-transform: uppercase;
}

.mailFieldView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
}

.mailFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 43%;
}

.colView {
  display: flex;
  gap: 10px
}

.colView2 {
  display: block;
  margin-left: -7.3%;
}

.containerStyleView {
  justify-content: center;
  display: flex;
  margin-left: auto;
  padding-bottom: 3%;
  padding-top: -100%;
  max-width: 700px !important;
  width: 100% !important;
}

.containerStyleView2 {
  justify-content: center;
  display: block;
  margin-left: -31%;
}

.labelMailView {
  margin-right: 68%;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: 'Nunito Sans', sans-serif;

  color: #1269B0;
  opacity: 1;
}

.labelMailView2 {
  font-family: 'Nunito Sans', sans-serif;
  color: #1269B0;
  margin-left: 25%;
  font-size: 100%;
}

.btnSearchView {
  width: 300px;
  height: 50px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  margin-bottom: -2%;
}

.btnSearchView2 {
  width: 300px;
  height: 50px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  font: normal normal normal 18px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  margin-left: -2%;
}

.btnBackView {
  width: 300px;
  height: 50px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
}

.btnBackView2 {
  width: 300px;
  height: 50px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  font: normal normal normal 18px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  margin-top: -12%;
  margin-left: -2.8%;
}

.btnBackBox {
  margin-top: -4%;
}

.btnBackBox2 {
  /* margin-top: 2%; */
  background-color: #FFFFFF;
}</style>